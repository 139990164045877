import React from "react";
import Helmet from "react-helmet";
import SEO from "../components/seo";
const SITE_URL = "https://thebrowser.company";
import OGImage from "../images/og.png";

const SignupPage = () => (
    <div>
        <SEO title="Sign up | The Browser Company"/>
        <Helmet>
            <meta http-equiv="refresh" content="0;URL='https://browserco.typeform.com/to/l9lYbJtU#source=tw'"/>
            <meta property="og:image" content={`${SITE_URL}${OGImage}`}/>
            <meta property="og:image:width" content="1200"/>
            <meta property="og:image:height" content="643"/>
        </Helmet>
    </div>
)

export default SignupPage;